@media only screen and (min-width: 1024px) {
  .vedio {
    width: 100%;
    height: 500px;
    margin-top: 50px;
  }

  .vedio_see {
    margin: 0% auto;
    width: 60%;
    height: 400px;
  }
  .chiqish {
    width: 150px;
    position: relative;
    left: 50px;
    margin: 0 0 0 50px;
    height: 50px;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  .a {
    text-decoration: none;
  }
  .chiqish_text {
    font-style: italic;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    left: 5px;
    top: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .vedio {
    width: 100%;
    height: 500px;
    margin-top: 50px;
  }

  .vedio_see {
    margin: 0% auto;
    width: 60%;
    height: 400px;
  }
  .chiqish {
    width: 150px;
    position: relative;
    left: 50px;
    margin: 0 0 0 50px;
    height: 50px;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  .a {
    text-decoration: none;
  }
  .chiqish_text {
    font-style: italic;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    left: 5px;
    top: 5px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .vedio {
    width: 100%;
    height: 500px;
    margin-top: 50px;
  }

  .vedio_see {
    margin: 0% auto;
    width: 60%;
    height: 400px;
  }
  .chiqish {
    width: 150px;
    position: relative;
    left: 50px;
    margin: 0 0 0 50px;
    height: 50px;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  .a {
    text-decoration: none;
  }
  .chiqish_text {
    font-style: italic;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    left: 5px;
    top: 5px;
  }
}

@media only screen and (min-width: 340px) and (max-width: 768px) {
  .vedio {
    width: 100%;
    height: 500px;
    margin-top: 50px;
  }

  .vedio_see {
    margin: 0% auto;
    width: 100%;
    height: 400px;
  }
  .chiqish {
    width: 150px;
    position: relative;
    left: 50px;
    margin: 0 0 0 10px;
    height: 50px;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }
  .a {
    text-decoration: none;
  }
  .chiqish_text {
    font-style: italic;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    left: 5px;
    top: 5px;
  }
}
