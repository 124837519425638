.footer {
  height: 100px;
}
@media only screen and (min-width: 1024px) {
  .footer {
    width: 100%;

    background-color: gray;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .footer {
    width: 100%;

    background-color: gray;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .footer {
    width: 100%;

    background-color: gray;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
  }
}

@media only screen and (min-width: 340px) and (max-width: 768px) {
  .footer {
    width: 100%;
    height: 200px;
    background-color: gray;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
  }
}
