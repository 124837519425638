@media only screen and (min-width: 1024px) {
  .location {
    width: 700px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1400px) {
  .location {
    width: 900px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .location {
    width: 900px;
  }
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .location {
    width: 700px;
  }
}
@media only screen and (min-width: 340px) and (max-width: 768px) {
  .location {
    width: 300px;
  }
}
