ul {
  list-style: none;
}

@media only screen and (min-width: 1024px) {
  .navbar {
    width: 100%;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
    height: 65px;
  }

  .containers {
    width: 90%;
    margin: 0% auto;
  }

  .nav_img_div {
    width: 50%;
  }

  .nav_img {
    width: 10%;
  }

  .flexs {
    width: 90%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5%;
  }

  .nav_ul {
    width: 50%;
  }

  .ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .ul li {
    cursor: pointer;
  }

  .popover {
    display: none;
  }

  .nav_popover {
    display: none;
  }

  .dark_night {
    cursor: pointer;
    width: 25px;
  }

  .bg_color {
    background-color: blue;
    color: white;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1400px) {
  .navbar {
    width: 100%;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
    height: 65px;
  }

  .containers {
    width: 90%;
    margin: 0% auto;
  }

  .nav_img_div {
    width: 50%;
  }

  .nav_img {
    width: 15%;
  }

  .flexs {
    width: 90%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5%;
  }

  .nav_ul {
    width: 100%;
  }

  .ul {
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .ul li {
    cursor: pointer;
  }

  .popover {
    display: none;
  }

  .nav_popover {
    display: none;
  }

  .dark_night {
    cursor: pointer;
    width: 25px;
  }
  .bg_color {
    background-color: blue;
    color: white;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .navbar {
    width: 100%;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
    height: 65px;
  }

  .containers {
    width: 90%;
    margin: 0% auto;
  }

  .nav_img_div {
    width: 50%;
  }

  .nav_img {
    width: 20%;
  }

  .flexs {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5%;
    height: 65px;
  }

  .nav_ul {
    width: 100%;
  }

  .ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .ul li {
    cursor: pointer;
  }

  .popover {
    display: none;
  }

  .nav_popover {
    display: none;
  }
  .bg_color {
    background-color: blue;
    color: white;
  }
}

@media only screen and (min-width: 770px) and (max-width: 991px) {
  .navbar {
    width: 100%;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
    height: 65px;
  }

  .containers {
    width: 90%;
    margin: 0% auto;
  }

  .nav_img_div {
    width: 30%;
  }

  .nav_img {
    width: 40%;
  }

  .flexs {
    width: 90%;
    display: flex;
    align-items: center;
    height: 65px;
    justify-content: space-around;
    padding: 0.5%;
  }

  .nav_ul {
    width: 100%;
  }

  .ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .ul li {
    cursor: pointer;
  }

  .popover {
    display: none;
  }

  .nav_popover {
    display: none;
  }

  .bg_color {
    background-color: blue;
    color: white;
  }
}

@media only screen and (min-width: 340px) and (max-width: 768px) {
  .navbar {
    width: 100%;
    height: 65px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
  }

  .containers {
    width: 90%;
    margin: 0% auto;
  }

  .nav_img_div {
    width: 55%;
  }

  .nav_img {
    width: 25%;
  }

  .flexs {
    width: 90%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5%;
  }

  .nav_ul {
    display: none;
  }

  .popover {
    display: block;
  }

  .mt_li {
    margin: 2%;
  }

  .mt_li_btn {
    width: 50%;
    background-color: blue;
    margin: 2%;
    color: white;
  }
}
