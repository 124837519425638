.zamonaviy_class {
  line-height: 30px;
  word-spacing: 1px;
  font-weight: 550;
}
.typing {
  font-size: 22px;
  font-weight: 600;
}

@media only screen and (min-width: 1024px) {
  .header {
    width: 100%;
  }

  .header_flexs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header_cart {
    display: flex;
    justify-content: space-around;
    margin: 5%;
  }

  .carousels {
    width: 60%;
    padding: 2%;
  }

  .header_cart_div {
    background-color: #f8fffb;
    width: 15%;
    cursor: pointer;
    transition: 0.9s;
    box-shadow: 0 10px 30px 0 #0000001a;
    border-radius: 15px;
  }

  .header_cart_div:hover {
    background-color: white;
  }

  .header_cart_img {
    width: 100%;
    height: 70%;
    border-radius: 4px;
    margin: 0% auto;
    display: flex;
    justify-content: center;
  }

  .header_cart_text {
    text-align: center;
    margin-top: 15%;
  }

  .header_flexs_text {
    width: 40%;
    padding: 3%;
  }

  .header_text_red {
    color: blue;
    font-size: 28px;
  }

  .header_text_two {
    width: 100%;
    font-size: 25px;
    font-weight: 600;
  }

  .header_text_three {
    line-height: 40px;
  }

  .text_header {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }

  .header_btn {
    position: relative;
    top: 15px;
  }

  .testimonial {
    width: 100%;
    text-align: center;
    margin: 0% auto;
    padding: 2%;
  }

  .h1 {
    font-size: 50px;
    font-weight: 600;
  }

  .testimonial_footer {
    width: 100%;
    margin: 0 auto;
    padding: 1%;
    display: flex;

    align-items: center;
    justify-content: space-around;
  }

  .testimonial_footer_img {
    width: 100%;
    border-radius: 25px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
  }
  .img_cart_header {
    width: 100%;
  }
  .p {
    text-align: center;
    width: 80%;
    font-size: 25px;
  }

  .color_text_30 {
    color: red;
    font-weight: bold;
    font-size: 25px;
    font-style: italic;
  }
  .color_text_200 {
    color: blue;
    font-size: 25px;
    font-weight: bold;
    font-style: italic;
  }
  .malumotlar {
    width: 80%;
    flex-wrap: wrap;
    display: flex;
    text-align: left;
    justify-content: space-around;
  }
  .location_call {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    flex-wrap: wrap;
  }

  .phone_cart {
    margin-top: 10px;
  }

  .boglanish {
    width: 70%;
    height: 45px;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
  }

  .boglanish Input {
    width: 90%;
    border: 0;
    outline: none;
    padding-left: 10px;
  }

  .FaUser {
    margin-left: 5px;
  }

  .phone_number {
    width: 70%;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
  }

  .PhoneInput {
    width: 10%;
    border: 0;
    outline: none;
    padding-left: 10px;
  }

  .BsTelephoneFill {
    margin-left: 5px;
  }

  .malumot {
    margin: 5px;
    font-weight: 600;
    font-size: 25px;
  }

  .ariza {
    width: 80%;
    margin: 5px;
    font-style: italic;
  }

  .TextArea_inp {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .TextArea_inp::placeholder {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .yuborish_btn {
    width: 120px;
    padding: 5px;
    color: white;
    margin-top: 5px;
    background-color: green;
    border-radius: 10px;
    transition: 2s;
  }

  .yuborish_btn:hover {
    background-color: white;
    color: black;
    border: 1px solid aqua;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1400px) {
  .header {
    width: 100%;
  }

  .header_flexs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header_cart {
    display: flex;
    margin: 5%;
    flex-wrap: wrap;
  }

  .carousels {
    width: 60%;
    padding: 2%;
  }

  .header_cart_div {
    background-color: #f8fffb;
    width: 25%;
    margin: 2%;
    cursor: pointer;
    transition: 0.9s;
    box-shadow: 0 10px 30px 0 #0000001a;
    border-radius: 15px;
  }

  .header_cart_div:hover {
    background-color: white;
  }

  .header_cart_img {
    width: 100%;
    height: 130px;
    margin: 0% auto;
    display: flex;
    padding: 4%;
    justify-content: center;
  }

  .header_cart_text {
    text-align: center;
    margin-top: 10%;
  }

  .header_flexs_text {
    width: 60%;
    padding: 3%;
  }

  .header_text_red {
    color: blue;
  }

  .header_text_two {
    width: 100%;
    font-size: 25px;
    font-weight: 600;
  }

  .header_text_three {
    line-height: 25px;
  }

  .text_header {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }

  .header_btn {
    position: relative;
    top: 15px;
  }

  .testimonial {
    width: 100%;
    text-align: center;
    margin: 0% auto;
    padding: 2%;
  }

  .h1 {
    font-size: 50px;
    font-weight: 600;
  }

  .testimonial_footer {
    width: 100%;
    margin: 0 auto;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  .testimonial_footer_img {
    width: 100%;
    border-radius: 25px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
  }
  .img_cart_header {
    padding: 10px;
    margin-top: 30px;
    width: 100%;
  }
  .p {
    text-align: center;
    width: 80%;
    font-size: 25px;
  }

  .color_text_30 {
    color: red;
    font-weight: bold;
    font-size: 25px;
    font-style: italic;
  }
  .color_text_200 {
    color: blue;
    font-size: 25px;
    font-weight: bold;
    font-style: italic;
  }
  .malumotlar {
    width: 60%;
    flex-wrap: wrap;
    display: flex;
    margin-top: 30px;
    text-align: left;
    justify-content: space-around;
  }

  .location_call {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    flex-wrap: wrap;
  }

  .phone_cart {
    margin-top: 10px;
    text-align: center;
  }

  .boglanish {
    width: 70%;
    height: 45px;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid black;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .boglanish Input {
    width: 90%;
    border: 0;
    outline: none;
    padding-left: 10px;
  }

  .FaUser {
    margin-left: 5px;
  }

  .phone_number {
    width: 70%;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid black;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .PhoneInput {
    width: 80%;
    border: 0;
    outline: none;
    padding-left: 10px;
  }

  .BsTelephoneFill {
    margin-left: 5px;
  }

  .malumot {
    margin: 5px;
    font-weight: 600;
    font-size: 25px;
  }
  .flex_div_cart {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .ariza {
    width: 100%;
    margin: 5px;
    font-style: italic;
  }

  .TextArea_inp {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .TextArea_inp::placeholder {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .yuborish_btn {
    width: 120px;
    padding: 5px;
    color: white;
    margin-top: 5px;
    background-color: green;
    border-radius: 10px;
    transition: 2s;
  }

  .yuborish_btn:hover {
    background-color: white;
    color: black;
    border: 1px solid aqua;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .header {
    width: 100%;
  }

  .header_flexs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header_cart {
    display: flex;
    margin: 5%;
    flex-wrap: wrap;
  }

  .carousels {
    width: 60%;
    padding: 2%;
  }

  .header_cart_div {
    background-color: #f8fffb;
    width: 25%;
    margin: 2%;
    cursor: pointer;
    transition: 0.9s;
    box-shadow: 0 10px 30px 0 #0000001a;
    border-radius: 15px;
  }

  .header_cart_div:hover {
    width: 27%;
    background-color: white;
  }

  .header_cart_img {
    width: 100%;
    height: 150px;
    margin: 0% auto;
    display: flex;
    padding: 5%;
    justify-content: center;
  }

  .header_cart_text {
    text-align: center;
    margin-top: 20%;
  }

  .header_flexs_text {
    width: 60%;
    padding: 3%;
  }

  .header_text_red {
    color: blue;
  }

  .header_text_two {
    width: 100%;
    font-size: 25px;
    font-weight: 600;
  }

  .header_text_three {
    line-height: 25px;
  }

  .text_header {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }

  .header_btn {
    position: relative;
    top: 15px;
  }

  .testimonial {
    width: 100%;
    text-align: center;
    margin: 0% auto;
    padding: 2%;
  }

  .h1 {
    font-size: 25px;
    font-weight: 600;
  }
  .testimonial_footer {
    width: 100%;
    margin: 0 auto;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  .testimonial_footer_img {
    width: 100%;
    border-radius: 25px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
  }
  .img_cart_header {
    padding: 10px;
    margin-top: 30px;
    width: 100%;
  }
  .p {
    text-align: center;
    width: 80%;
    font-size: 25px;
  }

  .color_text_30 {
    color: red;
    font-weight: bold;
    font-size: 25px;
    font-style: italic;
  }
  .color_text_200 {
    color: blue;
    font-size: 25px;
    font-weight: bold;
    font-style: italic;
  }
  .malumotlar {
    width: 80%;
    flex-wrap: wrap;
    display: flex;
    margin-top: 30px;
    text-align: left;
    justify-content: space-around;
  }

  .location_call {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    flex-wrap: wrap;
  }

  .phone_cart {
    margin-top: 10px;
    text-align: center;
  }

  .boglanish {
    width: 70%;
    height: 45px;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
  }

  .boglanish Input {
    width: 90%;
    border: 0;
    outline: none;
    padding-left: 10px;
  }

  .FaUser {
    margin-left: 5px;
  }

  .phone_number {
    width: 70%;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
  }

  .PhoneInput {
    width: 80%;
    border: 0;
    outline: none;
    padding-left: 10px;
  }
  .flex_div_cart {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .BsTelephoneFill {
    margin-left: 5px;
  }

  .malumot {
    margin: 5px;
    font-weight: 600;
    font-size: 25px;
  }

  .ariza {
    width: 100%;
    margin: 5px;
    font-style: italic;
  }

  .TextArea_inp {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .TextArea_inp::placeholder {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .yuborish_btn {
    width: 120px;
    padding: 5px;
    color: white;
    margin-top: 5px;
    background-color: green;
    border-radius: 10px;
    transition: 2s;
  }

  .yuborish_btn:hover {
    background-color: white;
    color: black;
    border: 1px solid aqua;
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .header {
    width: 100%;
  }

  .header_flexs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    padding-top: 20px;
  }

  .header_cart {
    display: flex;
    margin: 5%;
    flex-wrap: wrap;
  }

  .carousels {
    width: 100%;
    padding: 2%;
  }

  .header_cart_div {
    background-color: #f8fffb;
    width: 25%;
    margin: 2%;
    cursor: pointer;
    transition: 0.9s;
    box-shadow: 0 10px 30px 0 #0000001a;
    border-radius: 15px;
  }

  .header_cart_div:hover {
    width: 27%;
    background-color: white;
  }

  .header_cart_img {
    width: 100%;
    height: 100px;
    margin: 0% auto;
    display: flex;
    padding: 5%;
    justify-content: center;
  }

  .header_cart_text {
    text-align: center;
    margin-top: 20%;
  }

  .header_flexs_text {
    width: 100%;
    margin: 2%;
  }

  .header_text_red {
    color: blue;
  }

  .header_text_two {
    width: 100%;
    font-size: 25px;
    font-weight: 600;
  }

  .header_text_three {
    line-height: 25px;
  }

  .text_header {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }

  .header_btn {
    position: relative;
    top: 15px;
  }

  .testimonial {
    width: 100%;
    text-align: center;
    margin: 0% auto;
    padding: 2%;
  }

  .h1 {
    font-size: 25px;
    font-weight: 600;
  }

  .testimonial_footer {
    width: 100%;
    margin: 0 auto;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  .testimonial_footer_img {
    width: 100%;
    border-radius: 25px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
  }
  .img_cart_header {
    padding: 10px;
    margin-top: 30px;
    width: 100%;
  }
  .p {
    text-align: center;
    width: 80%;
    font-size: 25px;
  }

  .color_text_30 {
    color: red;
    font-weight: bold;
    font-size: 25px;
    font-style: italic;
  }
  .color_text_200 {
    color: blue;
    font-size: 25px;
    font-weight: bold;
    font-style: italic;
  }
  .malumotlar {
    width: 80%;
    flex-wrap: wrap;
    display: flex;
    margin-top: 30px;
    text-align: left;
    justify-content: space-around;
  }
  .location_call {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    flex-wrap: wrap;
  }

  .phone_cart {
    margin-top: 10px;
    text-align: center;
  }

  .boglanish {
    width: 70%;
    height: 45px;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
  }

  .boglanish Input {
    width: 90%;
    border: 0;
    outline: none;
    padding-left: 10px;
  }

  .FaUser {
    margin-left: 5px;
  }

  .phone_number {
    width: 70%;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
  }

  .PhoneInput {
    width: 80%;
    border: 0;
    outline: none;
    padding-left: 10px;
  }

  .BsTelephoneFill {
    margin-left: 5px;
  }

  .malumot {
    margin: 5px;
    font-weight: 600;
    font-size: 25px;
  }

  .ariza {
    width: 100%;
    margin: 5px;
    font-style: italic;
  }
  .flex_div_cart {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .TextArea_inp {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .TextArea_inp::placeholder {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .yuborish_btn {
    width: 120px;
    padding: 5px;
    color: white;
    margin-top: 5px;
    background-color: green;
    border-radius: 10px;
    transition: 2s;
  }

  .yuborish_btn:hover {
    background-color: white;
    color: black;
    border: 1px solid aqua;
  }
}

@media only screen and (min-width: 340px) and (max-width: 768px) {
  .header {
    width: 100%;
  }

  .header_flexs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .header_cart {
    display: flex;
    margin: 5%;
    flex-wrap: wrap;
  }

  .carousels {
    width: 100%;
    padding: 2%;
  }

  .header_cart_div {
    background-color: #f8fffb;
    width: 43%;
    margin: 2%;
    cursor: pointer;
    transition: 0.9s;
    box-shadow: 0 10px 30px 0 #0000001a;
    border-radius: 15px;
  }

  .header_cart_div:hover {
    width: 45%;
    background-color: white;
  }

  .header_cart_img {
    width: 80%;
    margin: 0% auto;
    display: flex;
    padding: 5%;
    justify-content: center;
  }

  .header_cart_text {
    text-align: center;
    margin-top: 20%;
  }

  .header_flexs_text {
    width: 100%;
    margin: 25px 0;
  }

  .header_text_red {
    color: blue;
  }

  .header_text_two {
    width: 100%;
    font-size: 25px;
    font-weight: 600;
  }

  .header_text_three {
    line-height: 40px;
  }

  .text_header {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }

  .header_btn {
    position: relative;
    top: 15px;
  }

  .testimonial {
    width: 100%;
    text-align: center;
    margin: 0% auto;
    padding: 2%;
  }

  .h1 {
    font-size: 25px;
    font-weight: 600;
  }
  .testimonial_footer {
    width: 100%;
    margin: 0 auto;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  .testimonial_footer_img {
    width: 100%;
    border-radius: 25px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
  }
  .img_cart_header {
    padding: 10px;
    margin-top: 30px;
    width: 100%;
  }
  .p {
    width: 100%;
    font-size: 18px;
  }

  .color_text_30 {
    color: red;
    font-weight: bold;
    font-size: 25px;
    font-style: italic;
  }
  .color_text_200 {
    color: blue;
    font-size: 25px;
    font-weight: bold;
    font-style: italic;
  }
  .malumotlar {
    width: 80%;
    flex-wrap: wrap;
    display: flex;
    margin-top: 30px;
    text-align: left;
    justify-content: space-around;
  }
  .location_call {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    flex-wrap: wrap;
  }

  .phone_cart {
    text-align: center;
    margin-top: 10px;
  }

  .boglanish {
    width: 80%;
    height: 45px;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
  }
  .flex_div_cart {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .boglanish Input {
    width: 90%;
    border: 0;
    outline: none;
    padding-left: 10px;
  }

  .FaUser {
    margin-left: 5px;
  }

  .phone_number {
    width: 80%;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    border: 1px solid black;
    margin-bottom: 10px;
  }

  .PhoneInput {
    border: 0;
    outline: none;
    padding-left: 10px;
  }

  .BsTelephoneFill {
    margin-left: 5px;
  }

  .malumot {
    margin: 5px;
    font-weight: 600;
    font-size: 25px;
  }

  .ariza {
    width: 100%;
    margin: 5px;
    font-style: italic;
  }

  .TextArea_inp {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .TextArea_inp::placeholder {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }

  .yuborish_btn {
    width: 120px;
    padding: 5px;
    color: white;
    margin-top: 5px;
    background-color: green;
    border-radius: 10px;
    transition: 2s;
  }

  .yuborish_btn:hover {
    background-color: white;
    color: black;
    border: 1px solid aqua;
  }
  .react-tel-input .form-control {
    width: 180px;
  }

  .mt_potion {
    padding-top: 10px;
  }
}
